<template>
  <vx-card :title="title">
    <vs-tabs :value="active">
      <vs-tab label="New">
        <New></New>
      </vs-tab>
      <vs-tab label="Process">
        <Process></Process>
      </vs-tab>
      <vs-tab label="Complete">
        <Complete></Complete>
      </vs-tab>
      <vs-tab label="Export">
        <Export></Export>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import New from "./_tab/new-tab.vue";
import Process from "./_tab/process-tab.vue";
import Complete from "./_tab/complete-tab.vue";
import Export from "./_tab/Export.vue";
export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    New,
    Process,
    Complete,
    Export,
  },
  data() {
    return {
      title: "Staff Stock Opname",
    };
  },
};
</script>
