<template>
  <div>
    <div class="vx-row w-1/2 mb-6 mt-3">
      <div class="vx-col text-center mt-1">
        <span class="label">Request Date</span>
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          class="w-full"
          v-model="date"
          type="date"
          v-validate="'required'"
        />
      </div>
      <div class="vx-col">
        <vs-button
          @click="onClickBtnFilter"
          size="medium"
          color="primary"
          icon-pack="feather"
          icon="icon-search"
          title="Search"
        >
          Filter
        </vs-button>
      </div>
    </div>

    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @change-page="handleChangePage"
      @search="handleSearch"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <!-- <vs-th
                  ><vs-checkbox
                    v-model="selectAll"
                    @input="handleSelectAll"
                  />ALL</vs-th
                > -->
        <vs-th></vs-th>
        <vs-th sort-key="code">SOF ID</vs-th>
        <vs-th sort-key="name">Warehouse Code</vs-th>
        <vs-th sort-key="name">Request Date</vs-th>
        <vs-th sort-key="name">Count Type</vs-th>
        <vs-th sort-key="name"># of SKU</vs-th>
        <vs-th sort-key="name">Counted UOM/Qty</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-input-group flex">
              <vs-button
                class="ml-2"
                size="small"
                color="primary"
                icon-pack="feather"
                icon="icon-clipboard"
                @click="HandleEdit(tr.id)"
              ></vs-button>
              <vs-button
                class="ml-2"
                size="small"
                color="primary"
                icon-pack="feather"
                icon="icon-download"
                @click="ExportData(tr)"
              ></vs-button>
              <vs-button
                class="ml-2"
                size="small"
                color="primary"
                icon-pack="feather"
                icon="icon-upload"
                @click="ImportData(tr)"
              ></vs-button>
              <vs-button
                class="ml-2"
                size="small"
                color="primary"
                icon-pack="feather"
                icon="icon-printer"
                @click="PrintData(tr)"
              >
              </vs-button>
              <vs-button
                class="ml-2"
                size="small"
                color="primary"
                icon-pack="feather"
                icon="icon-check"
                @click.stop="HandleApprove(tr)"
              ></vs-button>
            </div>
          </vs-td>
          <vs-td :data="data[indextr].code">
            {{ data[indextr].code }}
          </vs-td>
          <vs-td :data="data[indextr].warehouse_code">
            {{ data[indextr].warehouse_code }}
          </vs-td>
          <vs-td :data="data[indextr].request_date">
            {{ data[indextr].request_date }}
          </vs-td>
          <vs-td :data="data[indextr].count_type">
            {{ data[indextr].count_type }}
          </vs-td>
          <vs-td :data="data[indextr].of_sku">
            {{ data[indextr].of_sku }}
          </vs-td>
          <vs-td :data="data[indextr].total_uom_real">
            {{ data[indextr].total_uom_real }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},

  data() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const tomorrowFormatted = tomorrow.toISOString().split("T")[0];
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      exportID: [],
      IDwasGenerate: [],
      showingData: true,
      selectAll: false,
      date: tomorrowFormatted,
      cartStockOpname: [],
      IDItem: [],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },

    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/stock-opname/table", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            request_date: this.date,
            status: 2,
          },
        })
        .then((resp) => {
          console.log("rrrrrrr", resp);
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;

            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleClickCheckBox() {
      console.log(">>>>iiii", this.cartStockOpname);
    },
    onClickBtnFilter() {
      let params = {
        ...this.table,
      };

      if (this.date) {
        params.date = moment(this.date).format("YYYY-MM-DD");
      }
      this.getData(params);
    },

    HandleEdit(id) {
      this.$router.push({
        name: "stock-opname-staff-count",
        query: { id: id, action: "Count" },
      });
      this.id = id;
      this.detail = true;
    },
    ImportData(id) {
      this.$router.push({
        name: "stock-opname-staff-import",
        query: { id: id, action: "Import" },
      });
      this.id = id;
      this.detail = true;
    },
    HandleApprove(item) {
      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/stock-opname/process", {
          id: item.id,
          status: "3",
          warehouse_id: item.warehouse_id,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Stock opname move to Process",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.getData();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }

          this.$vs.loading.close();
        });
    },
    ExportData(item) {
      if (item) {
        this.$http
          .post("/api/wms/v1/stock-opname/export", {
            title:
              "Stock_Opname_Staff_" +
              "WMS" +
              "_" +
              this.dateStart +
              "_-_" +
              this.dateEnd,
            type: "stock-opname-line",
            type_tab: "WMS",
            stock_opname_id: item.id,
            dateStart: moment().format("YYYY-MM-DD"),
            dateEnd: moment().format("YYYY-MM-DD"),
          })
          .then((resp) => {
            console.log("rrrrrrr", resp);
            if (resp.code == 200) {
              this.table.total = resp.data.total_record;
              this.table.totalPage = resp.data.total_page;
              this.table.totalSearch = resp.data.total_record_search;
              this.table.length = resp.data.total_record_per_page;
              this.table.data = resp.data.records;

              this.setStartEnd();
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select data first",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
      }
    },
  },
  watch: {
    detail() {
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {
    formatDate(val) {
      return moment(val)
        .add(-7, "h")
        .utc()
        .local()
        .format("DD MMM YYYY HH:mm:ss");
    },
  },
};
</script>
